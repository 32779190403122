import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

function OpenGraph({
  type,
  title,
  description,
  image,
  imageAlt,
  url,
  siteName,
  locale
}) {
  return (
    <Helmet>
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:alt" content={imageAlt} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:locale" content={locale} />
    </Helmet>
  );
}

OpenGraph.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  url: PropTypes.string,
  siteName: PropTypes.string,
  locale: PropTypes.string
};

OpenGraph.defaultProps = {
  type: 'website',
  title: '13jam.com',
  description: 'Maker of castazzo.com',
  image: 'https://13jam.com/static/open-graph.png',
  imageAlt: '13jam.com',
  url: 'https://13jam.com/',
  siteName: '13jam',
  locale: 'en'
};

export default OpenGraph;
