import React from 'react';
import PropTypes from 'prop-types';
import { Global, css } from '@emotion/core';
import styled from '@emotion/styled';

import Meta from './Meta/Meta';
import Footer from './footer';

const PageStyle = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

const InnerStyle = styled.div`
  margin: 0 auto;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PageLayout = ({ children }) => (
  <>
    <Meta />
    <Global
      styles={css`
        html {
          box-sizing: border-box;
          font-size: 16px;
          height: 100vh;
        }
        *,
        *:before,
        *:after {
          box-sizing: inherit;
        }
        body {
          margin: 0;
          padding: 0;
          font-size: 1rem;
          line-height: 2;
          height: 100vh;
        }
        a {
          text-decoration: none;
          color: #000;
        }
      `}
    />
    <PageStyle>
      <InnerStyle>{children}</InnerStyle>
      <Footer />
    </PageStyle>
  </>
);

PageLayout.propTypes = {
  children: PropTypes.any.isRequired
};

export default PageLayout;
