import React from 'react';
import styled from '@emotion/styled';

import PageLayout from '../components/layout';
import github from '../images/github.svg';
import twitter from '../images/twitter.svg';

const Container = styled.div`
  text-align: center;
  margin: auto;
`;
const StyledIcon = styled.img`
  width: 25px;
  margin: 15px;
`;

export default () => (
  <PageLayout>
    <Container>
      <h1>13jam.com</h1>
      <a href="https://twitter.com/13jamcom/">
        <StyledIcon src={twitter} alt="twitter" />
      </a>
      <a href="https://github.com/13jamcom/">
        <StyledIcon src={github} alt="github" />
      </a>
    </Container>
  </PageLayout>
);
