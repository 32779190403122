import React from 'react';

import TwitterCard from './TwitterCard';
import OpenGraph from './OpenGraph';

export default () => (
  <>
    <TwitterCard />
    <OpenGraph />
  </>
);
